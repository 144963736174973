<script>
import { EPMC_FUNDERS as funders } from '@/config'

const supporters = funders.concat([
  {
    name: 'EMBL',
    imageUrl: 'embl.png',
    imageWidth: '180',
    url: '//www.embl.org/',
  },
])

export default {
  data() {
    return {
      supporters,
      supporterLogoRatio: 0.7,
    }
  },
  methods: {
    supporterImageUrl(supporter) {
      return supporter.name === 'EMBL'
        ? require('@/assets/' + supporter.imageUrl)
        : require('@/assets/funders_logos/' + supporter.imageUrl)
    },
  },
}
</script>
<template>
  <div id="supporters-section">
    <hr class="thick" />
    <p class="h2">Supported by</p>
    <p>
      Support by a group of international science
      <a href="/Funders">Funders</a> makes Europe PMC possible.
    </p>
    <div id="supporters">
      <a
        v-for="supporter in supporters"
        :key="supporter.name"
        :href="supporter.url"
        :aria-label="supporter.name"
      >
        <img
          :src="supporterImageUrl(supporter)"
          :alt="supporter.name"
          :style="{
            width: supporter.imageWidth * supporterLogoRatio + 'px',
          }"
          class="supporter"
        />
      </a>
    </div>
  </div>
</template>
<style scoped lang="scss">
#supporters-section {
  margin-top: $base-unit * 6;
  text-align: center;
  #supporters {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    row-gap: $base-unit * 6;
    column-gap: $base-unit * 7;
    padding-bottom: $base-unit * 4;
  }
}
</style>
